<template>

<app-item route="Term" :params="{ term: term.id, type: term.type, parent: term.parent }">

	<app-icon class="item-icon" :icon="'term.type.' + term.type" v-tooltip="$constants.typeName[term.type]" />

	<app-item-badge v-if="category" route="Category" :params="{category: category.id}" :image="category.icon" :color="category.background" :tooltip="category.title" />

	<app-item-badge v-if="group" route="Group" :params="{group: group.id}" :image="group.image" :tooltip="group.title" />

	<app-item-badge v-if="item" route="Item" :params="{item: item.id}" :image="item.image" :tooltip="item.title" />

	<app-item-badge v-if="location" route="Location" :params="{location: location.id}" :image="location.logo" :tooltip="location.title" /> 

	<app-item-title :text="$highlight(term.title, highlight)" :subtitle="(term.auto) ? 'automated': 'custom'" />

</app-item>

</template>

<script>

export default {

	props: ['term', 'highlight'],

	computed: {

		category: function() {

			return (this.term.type !== this.$constants.type.location) ? this.$store.getters['categories/find']((this.term.type === this.$constants.type.category) ? this.term.parent : this.group.category) : false

		},

		group: function() {

			return (this.term.type === this.$constants.type.group || this.term.type === this.$constants.type.item) ? this.$store.getters['groups/find']((this.term.type === this.$constants.type.group) ? this.term.parent : this.item.group) : false

		},

		item: function() {

			return (this.term.type === this.$constants.type.item) ? this.$store.getters['items/find'](this.term.parent) : false

		},

		location: function() {

			return (this.term.type === this.$constants.type.location) ? this.$store.getters['locations/find'](this.term.parent) : false

		}

	}

}

</script>

<style scoped>

.item-icon {
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 4px;
	flex-shrink: 0;
	margin-right: 10px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

</style>
